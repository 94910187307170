@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.loader {
  animation: rotato 1s linear infinite;
}

@keyframes rotato {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
