@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.button {
  cursor: pointer;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: all 300ms;

  font-family: $monospace-font-family;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: var(--navy);
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @include for-mobile-screens {
    letter-spacing: 0.02em;
  }

  > svg {
    > circle {
      stroke: currentColor;
    }

    > path {
      fill: currentColor;
    }
  }
}

@mixin big {
  height: 112px;
  padding: 40px 32px;
  gap: 56px;

  &:hover,
  &:active {
    gap: 62px;
    padding: 40px 26px 40px 32px;
  }

  @include for-mobile-screens {
    height: 64px;

    width: 100%;
    padding: 24px 20px;
    justify-content: space-between;
    gap: 0;

    &:hover,
    &:active {
      gap: 0;
      padding: 24px 14px 24px 20px;
    }
  }
}

@mixin bigVideo {
  height: 112px;
  padding: 40px 8px 40px 32px;
  gap: 56px;

  @include for-mobile-screens {
    height: 64px;

    width: 100%;
    padding: 24px 4px 24px 20px;
    justify-content: space-between;
    gap: 0;
  }
}

@mixin small {
  padding: 20px;
  gap: 16px;
  height: 64px;

  &:hover,
  &:active {
    gap: 20px;
    padding: 20px 16px 20px 20px;
  }
}

@mixin xsmall {
  gap: 0;
  padding: 16px;
  height: 56px;

  &:hover,
  &:active {
    gap: 4px;
    padding: 16px 12px 16px 16px;
  }
}

@mixin main {
  background-color: var(--zest3);

  &:hover,
  &:active {
    background-color: var(--zest4);
  }
}

@mixin mainDark {
  color: var(--zest3);
  background-color: var(--navy);

  &:hover,
  &:active {
    color: var(--navy);
    background-color: var(--teal);
  }
}

@mixin secondary {
  background-color: var(--white);

  &:hover,
  &:active {
    background-color: var(--zest2);
  }
}

@mixin autoStore {
  color: var(--white);
  background-color: var(--autoStore);

  &:hover,
  &:active {
    background-color: var(--autoStore);
  }
}

.bigMain {
  @include big;
  @include main;
}

.bigMainDark {
  @include big;
  @include mainDark;
}

.bigSecondary {
  @include big;
  @include secondary;
}

.bigSecondaryVideo {
  @include bigVideo;
  @include secondary;
}

.smallMain {
  @include small;
  @include main;
}

.smallSecondary {
  @include small;
  @include secondary;
}

.smallAutoStore {
  @include small;
  @include autoStore;
}

.iconOnlyMain {
  @include xsmall;
  @include main;
}

.link {
  background: none;
  border: none;
  border-radius: 0;
  height: inherit;
  margin: 0;
  padding: 0;

  font-family: $monospace-font-family;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: var(--navy);

  &:hover,
  :active {
    color: var(--teal);
  }

  @include for-mobile-screens {
    font-size: 12px;
  }
}

.toggle {
  background: none;
  border: none;
  border-radius: 0;
  height: inherit;
  margin: 0;
  padding: 0;

  @include body2;
  font-weight: 700;
  color: var(--navy);
  text-transform: none;
}

.subnav {
  background: none;
  border: none;
  border-radius: 4px;
  margin: 0;
  padding: 12px;
  height: 46px;

  min-width: max-content;
  white-space: nowrap;

  @include body2;
  color: var(--navy);
  text-transform: none;

  &:hover:not(.active) {
    color: var(--grey5);
  }

  &.active {
    background-color: var(--grey2);
  }
}

.disabled {
  background-color: var(--grey4);
  color: var(--grey5);
  transition: none;
  cursor: not-allowed;
  pointer-events: none;
}

.video {
  position: relative;
  width: 96px;
  height: 96px;
  border-radius: 4px;
  overflow: hidden;

  @include for-mobile-screens {
    width: 56px;
    height: 56px;
  }
}
