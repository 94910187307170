@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.icon {
  vertical-align: middle;
}

.specialFill {
  &.primary {
    > path {
      stroke: var(--iconPrimaryDark);
      fill: none;
    }

    > rect {
      fill: var(--iconPrimaryDark);
      stroke: none;
    }
  }

  &.secondary {
    > path {
      stroke: var(--iconSecondary);
      fill: none;
    }

    > rect {
      fill: var(--iconSecondary);
      stroke: none;
    }
  }
}

.primary path {
  fill: var(--iconPrimaryDark);
}

.secondary path {
  fill: var(--iconSecondary);
}

.inactive path {
  fill: var(--iconInactive);
}

.white path {
  fill: var(--white);
}

.zest path {
  fill: var(--zest);
}

.navy path {
  fill: var(--navy);
}

.dark path {
  fill: var(--navy);
}

.grey path {
  fill: var(--grey);
}

.error path {
  fill: var(--error);
}

.xs {
  height: 16px;
  width: auto;
  line-height: 16px;
}

.s {
  height: 24px;
  width: auto;
  line-height: 24px;
  font-size: 24px;
}

.m {
  height: 32px;
  width: auto;
  line-height: 32px;
  font-size: 32px;
}

.l {
  height: 48px;
  width: auto;
  line-height: 48px;
  font-size: 48px;
}

.xl {
  height: 72px;
  width: auto;
  line-height: 72px;
  font-size: 72px;
}

.xxl {
  height: 96px;
  width: auto;
  line-height: 96px;
  font-size: 96px;
}

.text-align {
  width: 1em;
  height: 1em;
  top: 0.125em;
  position: relative;
  margin-right: 0.5em;
}

.left {
  $left: rotate(-90deg);

  -moz-transform: $left;
  -webkit-transform: $left;
  -o-transform: $left;
  -ms-transform: $left;
  transform: $left;
}

.right {
  $right: rotate(90deg);

  -moz-transform: $right;
  -webkit-transform: $right;
  -o-transform: $right;
  -ms-transform: $right;
  transform: $right;
}

.up {
  $up: rotate(-180deg);

  -moz-transform: $up;
  -webkit-transform: $up;
  -o-transform: $up;
  -ms-transform: $up;
  transform: $up;
}

.down {
  $down: rotate(0deg);

  -moz-transform: $down;
  -webkit-transform: $down;
  -o-transform: $down;
  -ms-transform: $down;
  transform: $down;
}
