@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
@import 'globals.scss';
@import 'react-loading-skeleton/dist/skeleton.css';


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

button {
  touch-action: manipulation;
  cursor: pointer;
  font: inherit;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

html {
  overflow-x: hidden;
}

html,
body {
  -webkit-overflow-scrolling: touch;
}

body {
  position: relative;
  width: 100%;
  max-width: $max-width-body;
  padding: 0 var(--padding);
  background-color: var(--grey3);
  font-family: $default-font-family;
  font-size: 16px;
  margin: auto;
  overflow-x: hidden;
}

main {
  transform-origin: bottom center;
  overflow: hidden;
  border-radius: 24px;

  &.builder {
    border-radius: 0;
    background-color: #f6f7f7;
  }

  @include for-mobile-screens {
    border-radius: 12px;
  }
}

button {
  background: transparent;
  border: none;
  outline: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
} /* Firefox 18- */
input:focus::-moz-placeholder {
  color: transparent;
} /* Firefox 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
} /* oldIE 😉 */

*:focus-visible {
  outline: 2px solid var(--teal);
}
