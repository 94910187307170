@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.columns {
  display: grid;
  grid-template-columns: repeat($columns-desktop, 1fr);
  grid-column-gap: var(--gridColumnGap);
  grid-row-gap: var(--gridColumnGap);
  width: 100%;
  height: 100%;
  max-width: $max-width-content;
  margin: auto;

  @include for-mobile-screens {
    display: block;
  }
}

@mixin column-start-list {
  @for $i from 1 through $columns-desktop {
    &.start-#{$i} {
      grid-column-start: #{$i};
    }
  }
}

@mixin column-span-list {
  @for $i from 1 through $columns-desktop {
    &.span-#{$i} {
      grid-column-end: span #{$i};
    }
  }
}

.column {
  @include column-start-list;
  @include column-span-list;
}
