@import "variables.scss"; @import "mixins.scss"; @import "media.scss";


.dialog {
  position: fixed;
  bottom: var(--padding);
  right: var(--padding);
  box-shadow: 0px 1px 2px rgba(17, 30, 45, 0.08);
  z-index: 9999;
  background-color: var(--navy);
  max-width: 569px;
  padding: 24px;
  border-radius: 24px;
}

.panel {
  padding: 0 24px;
  
}
 
.leftColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

}

.topRow {
  @include for-mobile-screens {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.title {
  font-size: 26px;
}

.dismissButton {
  color: var(--zest3)
}

.image { 
  position: relative;
  height: 65px;
  border-radius: 12px;
  overflow: hidden;
  @include for-mobile-screens {
    height: 160px;
  }
}

.profileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include for-mobile-screens {
    align-items: center;
    margin: 16px;
  }
}

.submitButton {
  margin-top: 16px;
  height: 43px;
}

.list {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: calc(var(--padding) / 2);
  padding:var(--padding);
  background: var(--white);
  border-radius: 16px;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
  font-weight: bold;
}

.buttonClose {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: var(--navy);
  padding: 6px;
  border-radius: 50%;
  border: 1px solid var(--grey5);

  &:hover {
    svg path {
      transition: all 0.3s;
      fill: var(--grey5);
    }
  }
}

.sheet {
  [data-rsbs-overlay],
  [data-rsbs-root]:after {
    background: var(--navy);
  }
  [data-rsbs-footer] {
    box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)),
      0 2px 0 var(--navy);
  }
  [data-rsbs-header]:before {
    background-color: var(--zest3);
  }
}


