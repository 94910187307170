@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  position: relative;
  height: 100%;

  > span {
    height: 100% !important;
  }

  img {
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  &.loaded {
    img {
      opacity: 1;
    }
  }
}
