@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.h1 {
  @include h1();
}
.h2 {
  @include h2();
}
.h3 {
  @include h3();
}
.h4 {
  @include h4();
}

.label {
  @include label();
}

.blockquote {
  @include blockquote();
}

.navy {
  color: var(--navy);
}

.grey {
  color: var(--grey);
}

.grey5 {
  color: var(--grey5);
}

.zest3 {
  color: var(--zest3);
}

.teal {
  color: var(--teal);
}

.white {
  color: var(--white);
}
