@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.container {
  position: absolute;
  inset: 0;
}

.video {
  position: absolute; // contains it to its parent size
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}

.cover {
  position: absolute;
  inset: 0;
}
