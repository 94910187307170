@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.b1 {
  @include body1(); // font-size: 24px;
}

.b2 {
  @include body2(); // font-size: 16px;
}

.b3 {
  @include body3(); // font-size: 14px;
}

.label {
  @include label(); // font-size: 14px; mono
}

.button {
  @include button();
}

.navLink {
  @include nav-link();

  &:hover,
  :focus {
    color: var(--grey5);
  }
}

.navy {
  color: var(--navy);
}

.grey {
  color: var(--grey);
}

.grey2 {
  color: var(--grey2);
}

.grey5 {
  color: var(--grey5);
}

.zest3 {
  color: var(--zest3);
}

.teal {
  color: var(--teal);
}

.white {
  color: var(--white);
}


.error {
  color: var(--error);
}
