// OVERRIDES: use this for very specific styling properties

// Stats that is absolutely offset to overlap with the fulfillment section
// This is VERY specific to this image/layout, hence the override
#fulfillment + div {
  @media (min-width: 768px) and (max-width: 1380px) {
    > div {
      padding-top: 50px;
      padding-bottom: 50px;
      display: block;

      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: calc(100% - 100px);
        background: var(--colorNavy20);
      }

      div {
        border: 0;
      }

      p {
        margin: 0;
        padding-left: 50px;
      }
    }
  }
}

body #hubspot-messages-iframe-container.widget-align-right {
  z-index: var(--zMid) !important; // Below the cookie banner

  @media (min-width: 768px) {
    right: 16px !important;
  }

  @media (min-width: $max-width-body) {
    right: calc(16px + (100vw - #{$max-width-body}) / 2) !important;
  }
}

.grecaptcha-badge {
  z-index: var(--zMid); // reCaptcha badge should always be on top
  // visibility: hidden; //maybe just hide it?
}


[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: var(--zTop) !important;
}