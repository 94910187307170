// Website variables, they live here for now
// refer to https://www.figma.com/file/KcUEffIsNTycuhMYhfUuKk/PIO---Website-Sprint-03?node-id=956%3A40534
:root {
  // web 2 new colors
  --teal: #1dbfbf;
  --zest: #ffffd2;
  --navy: #111e2d;
  --white: #fff;
  --grey: #b2b0ac;
  --zest2: #fcfef2;
  --zest3: #f0f9be;
  --zest4: #f6fbd8;
  --grey2: #f0efee;
  --grey3: #d1d0cd;
  --grey4: #b2b0ac;
  --grey5: #687076;
  --error: #ff395d;
  --autoStore: #E11B22;
  // old colors
  --colorZest: #f0f9be;
  --colorNavy: #111e2d;
  --colorEarth: #b2b0ac;
  --colorTurquoise: #1dbfbf;
  --colorTurquoiseLight: #a2e3e3;
  --colorNavy80: #414b57;
  --colorNavy60: #707881;
  --colorNavy40: #a0a5ab;
  --colorNavy20: #cfd2d5;
  --colorNavy5: #f3f4f4;
  --colorEarth80: #c1c0bd;
  --colorEarth60: #d1d0cd;
  --colorEarth40: #e0dfde;
  --colorEarth20: #f0efee;
  --colorEarth5: #fbfbfb;
  --colorDarkBlue: #516879;
  --colorDarkGreen: #4d5744;
  --colorDarkYellow: #795715;
  --colorDarkRed: #7c3131;
  --colorBlue: #85aedc;
  --colorBlueGray: #414b57;
  --colorGreen: #76c791;
  --colorYellow: #ffd66d;
  --colorRed: #f5837d;
  --colorPaleBlue: #e5f2f9;
  --colorPaleGreen: #e0f4d9;
  --colorPaleYellow: #faedcc;
  --colorPaleRed: #fcdcd5;
  --colorWhite: #ffffff;
  --colorPlaceholder: var(--colorNavy40);
  --colorBuilderBackground: #F3F5F9;
  --colorZestTransparent: rgba(240, 249, 190, 0.3);
  --fontMono: var(--font-mono);
  --buttonMinWidth: 198px;


  --navBarHeight: 64px;
  --navColor: var(--colorZest);
  --navBgColor: none;
  --navBorderColor: var(--colorZestTransparent);
  --navLinkHeight: 46px;
  --navIconColor: var(--colorZest);

  --typoPrimaryDark: var(--colorNavy);
  --typoSecondaryDark: var(--colorNavy60);
  --typoPrimaryLight: var(--colorWhite);
  --typoSecondaryLight: var(--colorNavy20);
  --typoPrimaryZest: var(--colorZest);
  --typoInactive: #9a9ea5; // TODO: Only temporary - check and update this

  --iconPrimaryDark: var(--colorNavy);
  --iconPrimaryLight: var(--colorWhite);

  --iconSecondary: #565e71; // TODO: Only temporary - check and update colour
  --iconInactive: #9a9ea5; // TODO: Only temporary - check and update colour
  --iconError: #ff2d55; // TODO: Only temporary - check and update colour
  --iconGreen: #76c791; // TODO: Only temporary - check and update colour

  --backgroundPrimaryLight: var(--colorWhite);
  --backgroundSecondaryLight: var(--colorNavy5);
  --backgroundPrimaryDark: var(--colorNavy);

  --backgroundOverlay: rgba(
    17,
    30,
    45,
    0.8
  ); // TODO: Only temporary - check and update colour
  --backgroundCanvas: var(
    --colorNavy5
  ); // TODO: Only temporary - check and update colour

  --gridPadding: 23px;
  --padding: 8px;
  --gridColumnGap: var(--padding);
  --radius: 24px;

  --sectionPaddingSmall: var(--padding); // 8px
  --sectionPaddingMedium: calc(var(--padding) * 7); // 56px
  --sectionPaddingLarge: calc(var(--padding) * 9); // 72px
  --firstSectionPaddingTop: calc(var(--padding) * 2); // 16px
  --lastSectionPaddingBottom: var(--padding); // 8px

  --zTop: 9999;
  --zMid: 999;
  --zBack: 9;
  // used for calc'ing font-sizes, paddings (effectively 1rem)
  --sizeBase: 16px;

  // These breakpoints won't work in media-queries. Use the scss variables instead.
  --breakpointTablet: 768px;
  --breakpointStandard: 1024px;
  --breakpointLarge: 1440px;

  // Overrides for react-spring-bottom-sheet
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: var(--colorWhite);
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: var(--sectionPaddingSmall);
  --rsbs-mr: var(--sectionPaddingSmall);
  --rsbs-overlay-rounded: 0px;
}

@media only screen and (min-width: 768px) {
  :root {
    --gridPadding: 28px;
    --sectionPaddingSmall: calc(var(--padding) * 3.5); // 56px
    --sectionPaddingMedium: calc(var(--padding) * 4.5); // 72px
    --sectionPaddingLarge: calc(var(--padding) * 7); // 112px
    --firstSectionPaddingTop: calc(var(--padding) * 7); // 112px
    --lastSectionPaddingBottom: calc(var(--padding) * 4.5); // 72px
  }
}

@media only screen and (min-width: 1024px) {
  :root {
    --gridPadding: 36px;
    --padding: 16px;
    --navBarHeight: 62px;
  }
}

@media only screen and (min-width: 1440px) {
  :root {
    --gridPadding: 44px;
  }
}
